import request from 'utils/request';
const api = {
  CLICK_COUNT_PAGE: '/api/admin/offer/click/count/page',
  CLICK_COUNT_SUPPLEMENT_PAGE: '/api/admin/offer/click/count/supplement/page',
};

// 分页
export function fetchClickCountPage(query) {
  return request({
    url: api.CLICK_COUNT_PAGE,
    method: 'get',
    params: query,
  });
}

// supplement
export function fetchClickCountSupplementPage(query) {
  return request({
    url: api.CLICK_COUNT_SUPPLEMENT_PAGE,
    method: 'get',
    params: query,
  });
}
